import React from "react";
import { useTranslation } from 'react-i18next';
import Images from "../Images"

const About = ()=> {
    const {t} = useTranslation()
    
return (
<div>
<div className="pageTitle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <h1>{t("aboutus")}</h1>
      </div>
    </div>
  </div>
</div>

    <div className="wel-head pd-x">
      <h2 className="wel-title">{t("about")}</h2>
        <span className="linee"></span>
        </div>
        
    
        <div className="about pd-y">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="about-img">
                            <div data-aos="fade-down" className="about-img-1">
                                <img src={Images.merge} alt="Image2"></img>
                            </div>
                        </div>
                    </div>
                    <div  className="text-about col-md-7">
                        <div data-aos="fade-down" className="section-header">
                            <h2>{t("about-us-title")}</h2>
                        </div>
                        <div className="about-text">
                            <p  data-aos="fade-down-left">{t("about-us")}</p>
                            <p  data-aos="fade-down-right">{t("about-us2")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

    <div style={{marginTop:100}} className="wel-head">
        <h2 className="wel-title">{t("ourteam")}</h2>
        <span className="linee"></span>
    </div>

    <div className="Tea ">
        <div className="container">
            <div className="row justify-content-center">

            <div data-aos="fade-up" data-aos-duration="3000" className="col-lg-3 col-md-4">
                <div className="card-box text-center">
                    <div className="user-pic">
                        <img src={Images.hussien} className="img-fluid" alt="User Pic"/>
                    </div>
                    <h3 className="title">{t("member0-name")}</h3>
                    <p className="post">{t("job0")}</p>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="3000" className="col-lg-3 col-md-4">
                <div className="card-box text-center">
                    <div className="user-pic">
                        <img src={Images.zayan} className="img-fluid" alt="User Pic"/>
                    </div>
                    <h3 className="title">{t("member1-name")}</h3>
                    <p className="post">{t("job1")}</p>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="3000" className="col-lg-3 col-md-4">
                <div className="card-box text-center">
                    <div className="user-pic">
                        <img src={Images.essam} className="img-fluid" alt="User Pic"/>
                    </div>
                    <h3 className="title">{t("member2-name")}</h3>
                    <p className="post">{t("job2")}</p>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="3000" className="col-lg-3 col-md-4">
                <div className="card-box text-center">
                    <div className="user-pic">
                        <img src={Images.mohamed} className="img-fluid" alt="User Pic"/>
                    </div>
                    <h3 className="title">{t("member3-name")}</h3>
                    <p className="post">{t("job3")}</p>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="3000" className="col-lg-3 col-md-4">
                <div className="card-box text-center">
                    <div className="user-pic">
                        <img src={Images.yehia} className="img-fluid" alt="User Pic"/>
                    </div>
                    <h3 className="title">{t("member4-name")}</h3>
                    <p className="post">{t("job4")}</p>
                </div>
            </div>

                </div>
            </div>
        </div>
    </div>

  )
}
  
export default About;