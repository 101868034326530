const Images={
    // image1:require('./images/videobg.jpg').default,
    // image2:require('./images/6.jpg').default,
    essam:require('./images/essam.jpg').default,
    zayan:require('./images/khalil.jpg').default,
    mohamed:require('./images/mohamed abde azim.jpg').default,
    hussien:require('./images/mohamed hussien.jpg').default,
    yehia:require('./images/yehia mohamed.jpg').default,
    merge:require('./images/corporate2.jpg').default,
   
     
}
export default Images;