import React from 'react'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {useTranslation } from 'react-i18next';
import {Nav} from 'react-bootstrap';

const Services=()=> {
  const {t} = useTranslation()

return (
<div className='service-font'>
  <div className="pageTitle">
  <div className="container">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <h1>{t("service")}</h1>
      </div>
    </div>
  </div>
  </div>
  
    <div className="pd-y">
     <div className="wel-head">
      <h2 className="wel-title">{t("service")}</h2>
        <span className="linee"></span>
      </div>
      

      <div className="main-box">
         
      <div data-aos="flip-down" className="box">
          <div className="icon">
            <i><HomeOutlinedIcon  fontSize="large"/></i>
          </div>
          <h3>{t("service-title1")}</h3>
          <span className="line"></span>
          <h4>{t("service-1")}</h4>
        </div>

        <div data-aos="flip-down" className="box">
          <div className="icon">
            <i><DirectionsBoatOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title2")}</h3>
          <span className="line"></span>
          <h4>{t("service-2")}</h4>
        </div>
        
        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><LibraryBooksOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title3")}</h3>
          <span className="line"></span>
           <h4>{t("service-3")}</h4>
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><GavelOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title4")}</h3>
          <span className="line"></span>
           <h4>{t("service-4")}</h4>     
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><AttachMoneyOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title5")}</h3>
          <span className="line"></span>
           <h4>{t("service-5")}</h4>
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><WorkOutlineOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title5-2")}</h3>
          <span className="line"></span>
           <h4>{t("service-6")}</h4>
        </div>
        
        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><PersonOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title6")}</h3>
          <span className="line"></span>
           <h4>{t("service-7")}</h4>
           <h4>{t("service-5-1")}</h4>
           <h4>{t("service-5-2")}</h4>
           <h4>{t("service-5-3")}</h4>
           <h4>{t("service-5-4")}</h4>              
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><ApartmentOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title6-2")}</h3>
          <span className="line"></span>
           <h4>{t("service-8")}</h4>    
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><BusinessOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title7")}</h3>
          <span className="line"></span>
           <h4>{t("service-9")}</h4>    
        </div>
        
        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><CachedOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title8")}</h3>
          <span className="line"></span>
           <h4>{t("service-10")}</h4>    
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><LocalPoliceOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title9")}</h3>
          <span className="line"></span>
           <h4>{t("service-11")}</h4>    
        </div>

        <div data-aos="flip-down" className="box ">
          <div className="icon">
            <i><AttachMoneyOutlinedIcon fontSize="large"/></i>
          </div>
          <h3>{t("service-title10")}</h3>
          <span className="line"></span>
           <h4>{t("service-12")}</h4>
           <h4>{t("service-12-1")}</h4>
           <h4>{t("service-12-2")}</h4>
           <h4>{t("service-12-3")}</h4>
           <h4>{t("service-12-4")}</h4>
           <h4>{t("service-12-5")}</h4>
           <h4>{t("service-12-6")}</h4>
           <h4>{t("service-12-7")}</h4>    
        </div>

       


      </div>
      </div>
    </div>
        
    )
}
export default Services;