import React  from 'react'
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import "aos/dist/aos.css"
import { useTranslation } from 'react-i18next';
import Carousel from './Carousel';
import image from "../images/khalil zayan.jpg"
import image2 from "../images/mediation.jpg"
import image3 from "../images/merge.jpg"
import image4 from "../images/khalil zayan.jpg"
import {Col,Row} from 'react-bootstrap';

const Home =()=> {
    
    const {t} = useTranslation()
    
    return(
<div>
  
  <Carousel/>

  <div className='mb-5 home'>
    <div className="mb-5 ">
    <Row className="p-5 d-flex align-items-center">
      <div className="mt-5 d-flex justify-content-center col-sm-4"> 
      <img style={{width:"50%"}} data-aos="fade-down"  src={image} alt="" />
      </div>
      <div style={{fontSize:20}} className="col-sm-7">
      <h3 className="titlee" data-aos="fade-down" >
        {t("welcome")}</h3> 
        <div className="d-flex justify-content-center">
          <p data-aos="fade-right">{t("Introduction")}</p> 
        </div>
      </div>
    </Row>
  </div>

  <div style={{backgroundColor:"#a5a5a5"}} className="mt-5 mb-5">
    <Row className="p-5 d-flex align-items-center">
      <div className="d-flex justify-content-center col-sm-4"> 
      <img style={{width:"80%"}} data-aos="fade-down"  src={image2} alt="" />
      </div>
      <div style={{fontSize:20}} className="col-sm-7">
      <h2 className="titlee" data-aos="fade-down" >
        {t("ourvision")}</h2> 
        <div className="d-flex justify-content-center">
          <p data-aos="fade-right">{t("vision")}</p> 
        </div>
      </div>
    </Row>
  </div>

  <div className="mt-5 mb-5 ">
    <Row className="p-5 d-flex align-items-center">
      <div className="d-flex justify-content-center col-sm-4"> 
      <img style={{width:"80%"}} data-aos="fade-down"  src={image3} alt="" />
      </div>
      <div style={{fontSize:20}} className="col-sm-7">
      <h2 className="titlee" data-aos="fade-down" >
        {t("intro")}</h2> 
        <div className="d-flex justify-content-center">
          <p data-aos="fade-right">{t("intro-2")}</p> 
        </div>
      </div>
    </Row>
  </div>

  <div style={{backgroundColor:"#a5a5a5"}} className="mt-5 mb-5 ">
    <Row className="p-5 d-flex align-items-center">
      <div className="d-flex justify-content-center col-sm-4"> 
      <img style={{width:"80%"}} data-aos="fade-down"  src={image2} alt="" />
      </div>
      <div style={{fontSize:20}} className="col-sm-7">
      <h2 className="titlee" data-aos="fade-down" >
        {t("message")}</h2> 
        <div className="d-flex justify-content-center">
          <p data-aos="fade-right">{t("ourmessage")}</p> 
        </div>
      </div>
    </Row>
  </div>
</div>

<div className="pd-y flex-container">
  <div id="home-about" className="wel-head">
      <h2 className="wel-title">{t("belifs")}</h2>
      <span className="linee"></span>
  </div>
  
  <div className="info-content">
        
    <div className="info-about effect">
      <h2 className="title">{t("belifs1")}</h2>
      <p className="info-paragraph">{t("belifs2")}</p> 
      <a href="services"><button type="button" className="btn btn-outline-warning">{t("servicee")}</button></a>
    </div>

    <div className="info-about mg effect ">
      <h2 className="title">{t("belifs3")}</h2>
      <p className="info-paragraph">{t("belifs4")}</p>  
    </div>

    <div className="info-about mg effect ">
      <h2 className="title">{t("belifs5")}</h2>
      <p className="info-paragraph">{t("belifs6")}
      </p>  
    </div>

    <div className="info-about effect">
      <h2 className="title">{t("belifs7")}</h2>
      <p className="info-paragraph">
      {t("belifs8")}
      </p>
    </div>

    </div>
  </div>
</div>
  

    
)
       
}  
export default Home;
