import React from 'react';
import { useTranslation } from 'react-i18next';


 const Carousel =()=>{

 const {t} = useTranslation()
    
  return (
    <div className='row'>
      <div  className="w-100 ai-page-header-div">
       <div className="row aboutUs-title d-flex flex-column align-items-center justify-content-center">
          <h1 data-aos="zoom-in-left" data-aos-duration="2500" style={{color:"#e9d023",fontSize:60}}>{t("zayan")}
          < span data-aos="zoom-in-right" data-aos-duration="2500" style={{color:"#e9d023",fontSize:60}}>{t("zayan2")}</span> </h1>
          
          <h3 data-aos="fade-right" data-aos-duration="2900" style={{color:"white",fontSize:60}}>
          {t("zayan3")}
          </h3>
          <h3 data-aos="fade-right" data-aos-duration="2900" style={{color:"white",fontSize:60}}>
          {t("zayan5")} 
          </h3>
          <h3 data-aos="fade-right" data-aos-duration="2900" style={{color:"white",fontSize:60}}> {t("zayan4")}</h3>
       </div>
      </div>
    </div>    

        )
    
}
export default Carousel;